import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { PageProps } from "gatsby";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import DocumentationSection from "components/docs/DocumentationSection";
import IntegWideTargetFieldConfig from "../../../assets/images/docs/guides/field-mapping/IntegWideTargetFieldConfig.svg";
import LINKS from "components/docs/navigation/links";
import { HelpCircle } from "lucide-react";
import { Text } from "@merge-api/merge-javascript-shared";

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const CreateFieldMappingAcrossAnIntegration = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Mapping Across an integration";
  const description =
    "Create a Field Mapping that applies across all Linked Accounts for a specific integration";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          Creating a mapping across an integration is useful if you want to map an additional field
          that is standard to a particular integration, without creating additional Field Mappings
          for each Linked Account.
        </p>
        <p>
          Integration-wide Field Mappings are available for frequently used Remote Fields - if
          you're not seeing the field you need, contact{" "}
          <a href="mailto:support@merge.dev?subject = Field Mapping Origin Field Inquire">
            support@merge.dev
          </a>
          . To see what integrations Merge supports for integration-wide mappings, visit our{" "}
          <a
            href={
              LINKS.HELP_CENTER.linkTo +
              "/en/articles/7236095-supported-integrations-for-integration-wide-field-mapping"
            }
            target="_blank"
          >
            Help Center
          </a>
          .{" "}
        </p>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Create a Field Mapping across an integration">
        <p className="mb-0">
          Click on an organization-wide target field or Common Model override field in the{" "}
          <a href="https://app.merge.dev/configuration/field-mappings/target-fields">
            Field Mapping
          </a>{" "}
          page in Advanced configuration. Under <b>Field Mappings</b> or{" "}
          <b>Overrides by integration</b>, you can search for and select an integration for which to
          create a mapping. Click <b>+ Field Mapping</b> or <b>+ Override</b> to select a Remote
          Field to map to the target field.
        </p>
        <div className="mt-9 mb-6 w-full items-center justify-center flex">
          <IntegWideTargetFieldConfig className="rounded-lg" />
        </div>
        <p>
          This integration-wide mapping will be created by default for any existing and new Linked
          Accounts of that integration. It will appear labeled as an{" "}
          <b>organization-wide mapping</b> in each Linked Account&apos;s Field Mapping page in your
          dashboard and the target field will not appear in Link as mappable for your users.
        </p>
      </DocumentationSection>
      <div className="bg-gray-0 rounded-lg px-6 py-5 flex flex-col mb-9 mt-6">
        <div className="flex flex-row items-center">
          <HelpCircle size={16} className="mr-1.5" />
          <Text className="font-semibold text-14">
            Not seeing the Remote Field you want in the dropdown?
          </Text>
        </div>
        <Text>
          A Remote Field must be returned in 95% of all Production Linked Accounts in Merge to be
          available for integration-wide mapping. Any integration that does not have at least 25
          Production Linked Accounts will not show any fields as available for integration-wide
          Field Mappings.
        </Text>
      </div>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default CreateFieldMappingAcrossAnIntegration;
